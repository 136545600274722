html {
  scroll-behavior: smooth;
}

div {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: #ddd;
  scrollbar-width: 10px;
  scrollbar-color: #a3a3a3;
  display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(196, 196, 196);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(230, 230, 230);
}

::-webkit-scrollbar-track {
  background: rgb(53, 53, 53);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 1rem;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  color: #F38EBA;
  text-shadow: #F38EBA 0px 0 0px;
  transition: text-shadow .35s ease-in-out;
}

h1:hover,
h2:hover,
h3:hover,
h4:hover,
h5:hover,
h6:hover {
  text-shadow: #F38EBA 0px 0 20px;
}

h1 {
  font-size: 3rem;
  letter-spacing: 1rem;
}

h2 {
  font-size: 2rem;
  letter-spacing:.5rem;
}

p, li {
  font-family: 'EB Garamond', sans-serif;
  font-size: 1.45em;
}

a {
  font-family: 'EB Garamond', sans-serif;
  transition: color .25s ease-in-out;
  color: inherit;
}

a:hover,
a:active {
  color: #F38EBA;
}

.greyed {
  color: #777777;
  transition: color .5s ease;
}

.active {
  color: #ffffff;
  transition: color .5s ease;
}

.full {
  min-height: 100vh;
  max-height: 100%;
  min-width: 100%;
  max-width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
}

input, select, button {
  padding: .75rem;
  background-color: transparent;
  color: #ddd;
  border-radius: 5px;
  border: 1px solid #ddd;
  outline: none;
  transition: border .2s ease-in-out;
  transition: background-color .2s ease-in-out;
  font-family: 'EB Garamond', sans-serif;
  font-size: 1.1rem;
}

button {
  cursor: pointer;
}

select {
  cursor: pointer;
}

.card {
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  max-width: 100%;
  border: 1px solid #fff;
  border-radius: 5px;
  text-shadow: #F38EBA 0px 0 0px;
  transition: border .2s ease-in-out;
  transition: text-shadow .35s ease-in-out;
}

.card:hover {
  text-shadow: #F38EBA 0px 0 20px;
  border: 1px solid #F38EBA;
}

.absolute-full {
  left: 0;
  max-height: 100%;
  max-width: 100vw;
  min-height: 100vh;
  min-width: 100%;
  position: absolute;
  top: 0;
}

.hero-cover {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  /* background-color: black; */
  z-index: 2;
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  background-color: transparent;
  color: black;
  z-index: 1;
  overflow-wrap: break-word;
  overflow: hidden;
}

.binary-text {
  font-size: clamp(.6rem, 1.5vw, 1.5vh);
  color: rgba(243,142,186, 1);
  white-space: pre;
  font-weight: bold;
}

.hero-text {
  color: rgba(243,142,186, .9); 
  font-size: clamp(2.2rem, 5vw, 7rem);
  text-align: center;

  letter-spacing: 1rem;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
}

.footer-icon {
  text-shadow: #F38EBA 0px 0 0px;
  font-size: 2rem;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  transition: text-shadow .35s ease-in-out;
  transition: color .35s ease-in-out;
}

.footer-icon:hover {
  text-shadow: #F38EBA 0px 0 20px;
  color: #F38EBA;
}

.text-center {
  text-align: center;
}

.footer-description {
  color: #ffffff;
}

.footer-description span {
  color: #F38EBA;
  transition: text-shadow .35s ease-in-out;
  text-shadow: #F38EBA 0px 0 0px;
}

.footer-description span:hover {
  text-shadow: #F38EBA 0px 0 20px;
}
